/*!
 * Bootstrap (https://getbootstrap.com/)
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@import './bootstrap/functions';

// stylelint-disable
$primary: #007bff;
$secondary: #6c757d;
$success: #2fc787;
$info: #32bfc8;
$warning: #fdbf21;
$danger: #ef5164;
$light: #f8f9fa;
$dark: #393f49;
// stylelint-enable

@import './bootstrap/variables';

$brand: #007bff !default;

// stylelint-disable
$theme-colors: map-merge(
  (
    'brand': $brand
  ),
  $theme-colors
);

$breadcrumb-divider: none;

// stylelint-enable

@import './bootstrap/mixins';
@import './bootstrap/root';
@import './bootstrap/reboot';
@import './bootstrap/type';
@import './bootstrap/images';
@import './bootstrap/code';
@import './bootstrap/grid';
@import './bootstrap/tables';
@import './bootstrap/forms';
@import './bootstrap/buttons';
@import './bootstrap/transitions';
@import './bootstrap/dropdown';
@import './bootstrap/button-group';
@import './bootstrap/input-group';
@import './bootstrap/custom-forms';
@import './bootstrap/nav';
@import './bootstrap/navbar';
@import './bootstrap/card';
@import './bootstrap/breadcrumb';
@import './bootstrap/pagination';
@import './bootstrap/badge';
@import './bootstrap/jumbotron';
@import './bootstrap/alert';
@import './bootstrap/progress';
@import './bootstrap/spinners';
@import './bootstrap/toasts';
@import './bootstrap/media';
@import './bootstrap/list-group';
@import './bootstrap/close';
@import './bootstrap/modal';
@import './bootstrap/tooltip';
@import './bootstrap/popover';
@import './bootstrap/carousel';
@import './bootstrap/utilities';
@import './bootstrap/print';
