.dzu-dropzone {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  min-height: 120px;
  overflow: scroll;
  margin: 0 auto;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.15s linear;
  -o-transition: all 0.15s linear;
  transition: all 0.15s linear;
  border: 1px solid #e6ecf0;
  background-color: #fbfcfc;
  border-radius: 0.25rem;
}

.dzu-dropzone.is-invalid {
  background-color: #fef9fa;
  border-color: rgba(220, 53, 69, 0.25);
}
