:root {
  --background: rgb(252 252 252);
  --a1: rgba(0 0 0 / 0.05);
  --a2: rgba(0 0 0 / 0.1);
  --foreground: rgb(28 28 29);
  --shadow: 0px 6px 20px rgb(0 0 0 / 20%);
  --unit: 8px;}

.wrapPosition{
  z-index:99999;
  .wrapAnimatorStyle{
    opacity: 0;
    transform: scale(0.99);
    max-width: 600px;
    width: 100%;
    background: var(--background);
    color: var(--foreground);
    border-radius: 8px;
    overflow: hidden;
    box-shadow: var(--shadow);
    pointer-events: auto;
  }
  .searchKbar{
    padding: 12px 16px;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
    outline: none;
    border: none;
    background: var(--background);
    color: var(--foreground);
  }

  .kbarList {
    box-sizing: border-box;
    padding: 12px 16px;
    background: rgb(238, 238, 238);
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    // &:hover{
    // border-left: 2px solid var(--foreground);
    // }
    &__name{
      display: flex;
      gap: 8px;
      align-items: center;
      // justify-content:center;
      font-size: 14px;
    }
    &__shortCut{
      display: grid;
    grid-auto-flow: column;
    gap: 4px;

    }
  }
}
kbd{
  color:black;
  padding: 4px 6px;
  background: rgba(0,0,0,0.1)!important;
  border-radius: 4px;
  font-size: 14px
}
