.demo {
  > .card,
  > .list-group {
    display: inline-block;
    width: 32rem;
    max-width: 100%;

    .list-group-item {
      color: $color_secondary;

      &.active {
        color: #fff;
      }
      &.disabled {
        color: darken($color_secondary, 12);
      }
    }
  }
  > .table-responsive-md > .dataTables_wrapper > .rui-datatable {
    min-width: 600px;
  }
  > .modal {
    position: static;
    display: block;
    background-color: rgba($color_grey_3, 0.85);

    &.fade {
      display: none;
    }
  }
  .spinner-border,
  .spinner-grow {
    margin-right: 10px;
  }
  h6:last-child {
    margin-bottom: -3px;
  }
  p:last-child {
    margin-bottom: -6px;
  }
  .display-4:last-child {
    margin-bottom: -9px;
  }
  .lead:last-child {
    margin-bottom: -8px;
  }
  .blockquote:last-child {
    margin-bottom: 0;
  }
  .table {
    margin-bottom: 0;

    + .table {
      margin-top: 20px;
    }
  }
  .progress + .progress {
    margin-top: 10px;
  }
  .rui-touchspin {
    max-width: 250px;

    + .rui-touchspin {
      margin-top: 20px;
    }
  }
}
.demo-icons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 25px 20px;
  color: $color_secondary;
  text-align: center;
  cursor: pointer;
  background-color: $color_grey_1;
  border: 1px solid $color_grey_1;
  border-radius: 0.25rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;

  > .rui-icon {
    width: 26px;
    height: 26px;
    stroke: $color_text_1;
    margin-bottom: 15px;
  }

  &:hover {
    background-color: $color_grey_2;
    border-color: darken($color_separator, 6);
  }
}
.pay-icons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > .rui-icon {
    width: 40px;
    height: 40px;
    stroke: $color_success;
  }
}
.demo-colors {
  padding: 25px 30px;
  margin-top: 10px;
  border-radius: 0.25rem;
}

.button-calc button {
  margin: 5px 5px;
  text-align: center !important;
  align-content: center;
  height: 100px;
  font-size: 23px;
}

.mayar-button {
  font-family: 'Helvetica Neue', Helvetica, Arial, Sans-serif;
  font-weight: 400;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 3px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 3px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 3px;
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  -moz-transition-duration: 0.125s;
  -o-transition-duration: 0.125s;
  -webkit-transition-duration: 0.125s;
  transition-duration: 0.125s;
  -moz-transition-property: all;
  -o-transition-property: all;
  -webkit-transition-property: all;
  transition-property: all;
  -moz-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  background-image: url('https://assets.mayar.id/button_bar.jpg');
  background-color: #fff;
  background-repeat: repeat-x;
  background-size: contain;
  color: #999;
  font-size: 16px;
  height: 50px;
  line-height: 52px;
  padding: 0 15px;
  text-decoration: none;
}
.mayar-button span {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  background-image: url('https://assets.mayar.id/button-logo.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 17px;
  width: 17px;
  margin: -2px 15px 0 0;
}
.mayar-button:hover {
  -moz-box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 3px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 3px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 3px;
  color: #777;
}
.mayar-button:active {
  -moz-box-shadow: inset rgba(0, 0, 0, 0.3) 0 0px 3px;
  -webkit-box-shadow: inset rgba(0, 0, 0, 0.3) 0 0px 3px;
  box-shadow: inset rgba(0, 0, 0, 0.3) 0 0px 3px;
  color: #888;
  background-color: #fefefe;
}
.white-space-enabled {
  white-space: pre-line;
}
.card-field .card-body {
  padding: 5px 10px 5px 20px !important;
}
.hover-pointer {
  cursor: pointer;
}
.kupon-button {
  padding: 0px 0px !important;
}
.react-tagsinput {
  border-radius: 5px;
  min-height: 39px;
}
.react-tagsinput--focused {
  border-color: #007bff !important;
}
.react-tagsinput-tag {
  background-color: #007bff !important;
  border: 1px solid #3293fa !important;
  color: #fff !important;
}
.react-tagsinput-input {
  width: 180px !important;
}
.checkout-table-image {
  height: 50px;
  width: 50px;
  object-fit: cover;
}
.table-checkout tbody td {
  padding: 0;
  vertical-align: middle;
  padding-bottom: 10px;
}
