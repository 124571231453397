.dropdown {
    position: relative;
    display: inline-flex;

    .dropdown-item {
        position: relative;
        display: flex;
        align-items: center;
        padding: 0;
        font-size: 13px;
        color: $color_grey_6;
        white-space: nowrap;

        > span:not([class]) {
            color: $color_text_1;
        }
        > .rui-dropdown-circle {
            position: absolute;
            top: -25%;
            left: -10px;
            width: 36px;
            height: 36px;
            background-color: $color_grey_1;
            border-radius: 100%;
            opacity: 0;
            transition: opacity .1s ease-in-out;
            z-index: -1;
        }
        > .rui-icon {
            display: inline-block;
            margin: 0 14px -1px;
            stroke: $color_grey_5;
            transition: stroke .1s ease-in-out;

            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }
        }
        &:hover,
        &.hover {
            color: $color_text_1;
            background-color: transparent;

            > span:not([class]) {
                color: $color_text_1;
            }
            > .rui-dropdown-circle {
                opacity: 1;
            }
            > .rui-icon {
                stroke: $color_text_1;
            }
        }
        &:focus,
        &.focus {
            background-color: transparent;
            outline: none;
        }
        &.active {
            color: $color_text_1;

            > span:not([class]) {
                color: $color_text_1;
            }
            > .rui-icon {
                stroke: $color_text_1;
            }
        }
        &:active,
        &.active {
            background-color: transparent;
        }
    }
}
.dropdown:not(.dropdown-hover) {
    &:focus,
    &.focus {
        color: $color_text_1;

        > span:not([class]) {
            color: $color_text_1;
        }
        > .rui-icon {
            stroke: $color_text_1;
        }
    }
    &:active {
        color: $color_brand;

        > span:not([class]) {
            color: $color_brand;
        }
        > .rui-icon {
            stroke: $color_brand;
        }
    }
    &.show > .dropdown-item {
        color: $color_text_1;
        background-color: transparent;

        > span:not([class]) {
            color: $color_text_1;
        }
        > .rui-dropdown-circle {
            opacity: 1;
        }
        > .rui-icon {
            stroke: $color_text_1;
        }
    }
}

// Switch Fixed
.dropdown .dropdown-item-switch.custom-switch,
.dropdown.show .dropdown-item-switch.custom-switch {
    padding-right: calc(3rem + 5px);
    padding-left: 0;

    .dropdown-item {
        cursor: pointer;

        &::before {
            top: calc(.15rem + 8px);
            right: calc(-3rem + 10px);
            left: auto;
        }
        &::after {
            top: calc(.15rem + 9px);
            right: calc(-2.1rem + 10px);
            left: auto;
        }
        &:hover::after {
            background-color: #fff;
        }
    }
    > input:checked ~ .dropdown-item {
        color: $color_brand;

        > span:not([class]) {
            color: $color_brand;
        }
        > .rui-icon {
            stroke: $color_brand;
        }
    }
}

// Hover
.dropdown-hover.hover {
    > .dropdown-menu::before {
        visibility: visible;
    }
    > .dropdown-item {
        color: $color_text_1;
        z-index: 4;

        > span:not([class]) {
            color: $color_text_1;
        }
        > .rui-dropdown-circle {
            opacity: 1;
        }
        > .rui-icon {
            stroke: $color_text_1;
        }
        &.btn-custom-round,
        > .btn-custom-round {
            color: $color_secondary;

            &::after {
                background-color: $color_grey_1;
            }
        }
    }
}

// Menu
.dropdown-menu {
    display: none;
    padding: 16px 15px;
    visibility: hidden;
    border: 1px solid $color_separator;
    border-radius: .25rem;
    box-shadow: 0 3px 10px rgba(0, 0, 0, .03);
    opacity: 0;
    z-index: 3;

    &.show {
        visibility: visible;
        opacity: 1;
    }

    &.hover,
    &.focus {
        display: block;
        will-change: opacity;
        transition: visibility .15s ease-in-out, opacity .15s ease-in-out;
    }

    &.nav .nav-link {
        white-space: nowrap;

        > .rui-nav-circle {
            top: 3px;
            left: -1px;
        }
    }

    .dropdown-item {
        padding: 9px 14px;

        > .rui-dropdown-circle {
            top: 3px;
            left: -1px;
        }
    }

    // Fixed Scroll menu
    > .dropdown-menu-scroll {
        padding: 15px 16px 15px 15px;
        margin: -15px -14px -15px -15px;

        > li {
            list-style: none;
        }
    }

    // Label
    .dropdown-menu-label {
        padding: 14px 10px 8px;
        color: $color_grey_5;
    }

    // Dropdown position and arrow
    &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        min-width: 30px;
        min-height: 30px;
        margin: -30px 0 0;
        z-index: 0;
    }
    &[x-placement="top-start"],
    &[x-placement="top-end"] {
        &::before {
            top: auto;
            right: 0;
            bottom: 0;
            left: 0;
            margin: 0 0 -30px;
        }
    }
    &[x-placement="right-start"],
    &[x-placement="right-end"] {
        &::before {
            top: 0;
            right: auto;
            bottom: 0;
            left: 0;
            margin: 0 0 0 -30px;
        }
    }
    &[x-placement="left-start"],
    &[x-placement="left-end"] {
        &::before {
            top: 0;
            right: 0;
            bottom: 0;
            left: auto;
            margin: 0 -30px 0 0;
        }
    }
    &[x-placement="top-start"] {
        margin-bottom: 20px;
        margin-left: -30px;
    }
    &[x-placement="top-end"] {
        margin-right: -30px;
        margin-bottom: 20px;
    }
    &[x-placement="bottom-start"] {
        margin-top: 20px;
        margin-left: -30px;
    }
    &[x-placement="bottom-end"] {
        margin-top: 20px;
        margin-right: -30px;
    }
    &[x-placement="right-start"] {
        margin-top: -30px;
        margin-left: 20px;
    }
    &[x-placement="right-end"] {
        margin-bottom: -30px;
        margin-left: 20px;
    }
    &[x-placement="left-start"] {
        margin-top: -30px;
        margin-right: 20px;
    }
    &[x-placement="left-end"] {
        margin-right: 20px;
        margin-bottom: -30px;
    }

    .btn + & {
        &[x-placement="top-start"] {
            margin-left: 0;
        }
        &[x-placement="top-end"] {
            margin-right: 0;
        }
        &[x-placement="bottom-start"] {
            margin-left: 0;
        }
        &[x-placement="bottom-end"] {
            margin-right: 0;
        }
        &[x-placement="right-start"] {
            margin-top: 0;
        }
        &[x-placement="right-end"] {
            margin-bottom: 0;
        }
        &[x-placement="left-start"] {
            margin-top: 0;
        }
        &[x-placement="left-end"] {
            margin-bottom: 0;
        }
    }
}

// Menu Scroll
.dropdown-menu-scroll {
    max-height: 200px;
    overflow-y: scroll;

    &::-webkit-scrollbar-track {
        background-color: #fff;
        border-radius: 4px;
    }
    &::-webkit-scrollbar {
        width: 4px;
        background-color: #fff;
    }
    &::-webkit-scrollbar-thumb {
        background-color: $color_grey_3;
        border-radius: 4px;
    }
}

// Small Paddings
.dropdown-menu-sm {
    padding: 11px 10px;

    .dropdown-item {
        padding: 5px 14px;
    }

    // Fixed Scroll menu
    > .dropdown-menu-scroll {
        padding: 14px 11px 14px 10px;
        margin: -10px -9px -10px -10px;
    }
}

// Triangle
.dropdown-triangle .dropdown-menu-triangle {
    position: absolute;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
    }
}

// Dropdown
.dropdown-triangle .dropdown-menu[x-placement="bottom-start"],
.dropdown-triangle .dropdown-menu[x-placement="bottom-end"],
.dropdown-triangle .dropdown-menu {
    > .dropdown-menu-triangle {
        top: 0;
        right: auto;
        bottom: auto;
        left: 30px;
        margin: -19px 0 0 -3px;
        border: 10px solid transparent;
        border-bottom: 9px solid #fff;

        &::before {
            margin: -12px 0 0 -11px;
            border: 11px solid transparent;
            border-bottom: 10px solid $color_separator;
        }
    }
}
.dropdown-triangle .dropdown-menu[x-placement="bottom-end"] > .dropdown-menu-triangle {
    right: 10px;
    left: auto;
}

// Dropup
.dropdown-triangle .dropdown-menu[x-placement="top-start"],
.dropdown-triangle .dropdown-menu[x-placement="top-end"] {
    > .dropdown-menu-triangle {
        top: auto;
        right: auto;
        bottom: 0;
        left: 30px;
        margin: 0 0 -19px -3px;
        border: 10px solid transparent;
        border-top: 9px solid #fff;

        &::before {
            margin: -9px 0 0 -11px;
            border: 11px solid transparent;
            border-top: 10px solid $color_separator;
        }
    }
}
.dropdown-triangle .dropdown-menu[x-placement="top-end"] > .dropdown-menu-triangle {
    right: 30px;
    left: auto;
}

// Dropright and Dropleft
.dropdown-triangle .dropdown-menu {
    // Dropright
    &[x-placement="right-start"] > .dropdown-menu-triangle {
        top: 30px;
        right: auto;
        bottom: auto;
        left: 0;
        margin: -3px 0 0 -19px;
        border: 10px solid transparent;
        border-right: 9px solid #fff;

        &::before {
            margin: -11px 0 0 -12px;
            border: 11px solid transparent;
            border-right: 10px solid $color_separator;
        }
    }
    &[x-placement="right-end"] > .dropdown-menu-triangle {
        top: auto;
        right: auto;
        bottom: 30px;
        left: 0;
        margin: 0 0 -3px -19px;
        border: 10px solid transparent;
        border-right: 9px solid #fff;

        &::before {
            margin: -11px 0 0 -12px;
            border: 11px solid transparent;
            border-right: 10px solid $color_separator;
        }
    }

    // Dropleft
    &[x-placement="left-start"] > .dropdown-menu-triangle {
        top: 30px;
        right: 0;
        bottom: auto;
        left: auto;
        margin: -3px -19px 0 0;
        border: 10px solid transparent;
        border-left: 9px solid #fff;

        &::before {
            margin: -11px 0 2px -9px;
            border: 11px solid transparent;
            border-left: 10px solid $color_separator;
        }
    }
    &[x-placement="left-end"] > .dropdown-menu-triangle {
        top: auto;
        right: 0;
        bottom: 30px;
        left: auto;
        margin: 0 -19px -3px 0;
        border: 10px solid transparent;
        border-left: 9px solid #fff;

        &::before {
            margin: -11px 0 2px -9px;
            border: 11px solid transparent;
            border-left: 10px solid $color_separator;
        }
    }
}
