.myrconfetti-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
}

.myrconfetti-wohoo {
  margin: auto;
}

[class|='confetti'] {
  position: absolute;
}

$colors: (#d13447, #ffbf00, #263672);

@for $i from 0 through 300 {
  $w: random(8);
  $l: random(100);
  .confetti-#{$i} {
    width: #{$w}px;
    height: #{$w * 0.4}px;
    background-color: nth($colors, random(3));
    top: -10%;
    left: unquote($l + '%');
    opacity: random() + 0.5;
    transform: rotate(#{random() * 360}deg);
    animation: drop-#{$i} unquote(4 + random() + 's') unquote(random() + 's') infinite;
  }

  @keyframes drop-#{$i} {
    100% {
      top: 110%;
      left: unquote($l + random(15) + '%');
    }
  }
}
